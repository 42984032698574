import { PropsWithChildren } from 'react'
import { WagmiProvider as Wagmi } from 'wagmi'

import { wagmiConfig } from '@/configs'

type WagmiProviderProps = PropsWithChildren

export default function WagmiProvider({ children }: WagmiProviderProps) {
  return <Wagmi config={wagmiConfig}>{children}</Wagmi>
}
