import type { AppProps } from "next/app";
import { NextPage } from "next";
import { ReactElement, ReactNode } from "react";

import WagmiProvider from "@/providers/WagmiProvider";
import QueryClientProvider from "@/providers/QueryClientProvider";
import ThemeProvider from "@/providers/ThemeProvider";
import RainbowKitProvider from "@/providers/RainbowKitProvider";

import "../../public/antd.min.css";
import "@rainbow-me/rainbowkit/styles.css";
import "@/styles/globals.scss";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page: ReactNode) => page);

  return (
    <WagmiProvider>
      <QueryClientProvider>
        <RainbowKitProvider>
          <ThemeProvider>
            {getLayout(<Component {...pageProps} />)}
          </ThemeProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
