import { ThemeConfig, theme as defaultTheme } from "antd";

export const theme: ThemeConfig = {
  algorithm: [defaultTheme.darkAlgorithm],
  token: {
    colorPrimary: "#D8FF76",
    colorSuccess: "#D8FF76",
    colorTextDescription: "#FFFFFF80",
    colorTextSecondary: "#FFFFFF80",
    colorText: "#FFFFFF",
    fontFamily: "Bw Gradual",
  },
  components: {
    Button: {
      colorPrimary: "#D8FF76",
      primaryColor: "#090A0B",
      borderRadius: 999,
    },
    Layout: {
      colorBgLayout: "#0A0A0A",
    },
  },
};
