import { PropsWithChildren } from 'react'
import {
  darkTheme,
  RainbowKitProvider as RainbowKit,
} from '@rainbow-me/rainbowkit'

type RainbowKitProviderProps = PropsWithChildren

export default function RainbowKitProvider({
  children,
}: RainbowKitProviderProps) {
  return <RainbowKit theme={darkTheme()}>{children}</RainbowKit>
}
